import React, { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { RecoilRoot, useRecoilState } from 'recoil';
import { BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';

import MainPage from './pages/MainPage';
import LoginPage from './pages/auth/LoginPage';
import SignupPage from './pages/auth/SignupPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import MainHeader from './components/MainHeader';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import NoticeListPage from './pages/notice/NoticeListPage';
import NoticeDetailPage from './pages/notice/NoticeDetailPage';
import NoticeEditPage from './pages/notice/NoticeEditPage';
import StatsCreatePage from './pages/stats/StatsCreatePage';
import CommonDialog from './components/CommonDialog';
import DialogController from './controllers/DialogController';
import SignupRequestsPage from './pages/admin/SignupRequestsPage';
import MyInfoPage from './pages/info/MyInfoPage';
import UsersPage from './pages/admin/UsersPage';
import AllStatsRequestsPage from './pages/admin/AllStatsRequestsPage';
import StatsResultPage from './pages/stats/StatsResultPage';
import StatsResponsePage from './pages/stats/StatsResponsePage';
import DBInfoPage from './pages/db/DBInfoPage';
import NotificationControlPage from './pages/admin/NotificationControlPage';
import GroupInsightPage from './pages/insight/GroupInsightPage';
import IndividualInsightPage from './pages/insight/IndividualInsightPage';
import NoticeNewPage from './pages/notice/NoticeNewPage';
import MainFooter from './components/MainFooter';


function App() {

  useEffect(() => {
    
  }, [])

  return (
    <RecoilRoot>
        <Router basename='/'>
          <MainHeader />
          <DialogController />
            <div style={{minHeight:'calc(100vh - 244px)', minWidth: 1000}}>
              <Routes>
                <Route path="/" element={<MainPage />} />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />

                <Route path="/notice" element={<NoticeListPage />} />
                <Route path="/notice/detail/*" element={<NoticeDetailPage />} />
                <Route path="/notice/edit/*" element={<NoticeEditPage />} />
                <Route path="/notice/new" element={<NoticeNewPage />} />

                <Route path="/my" element={<MyInfoPage />} />

                <Route path="/db" element={<DBInfoPage />} />


                <Route path="/stats/create" element={<StatsCreatePage />} />
                <Route path="/stats/result" element={<StatsResultPage />} />
                <Route path="/stats/response" element={<StatsResponsePage />} />

                <Route path="/admin/signup-requests" element={<SignupRequestsPage />} />
                <Route path="/admin/users" element={<UsersPage />} />
                <Route path="/admin/stats" element={<AllStatsRequestsPage />} />
                <Route path="/admin/notification" element={<NotificationControlPage />} />

                <Route path="/insight/group" element={<GroupInsightPage />} />
                <Route path="/insight/individual" element={<IndividualInsightPage />} />



              </Routes>
            </div>
          <MainFooter />
        </Router>
    </RecoilRoot>
  );
}

export default App;

import { SetterOrUpdater, useRecoilState } from "recoil";
import { dialogInfoState } from "./globalState";
import { DialogInfoState, StatResultTable } from "./interfaces";
import * as XLSX from 'xlsx';
import * as crypto from "crypto";

export const openNotiDialogWithSetter = (setState:SetterOrUpdater<DialogInfoState>, title: string) => {
  setState((prevState) => ({
    ...prevState,
    isOpen: true,
    title: title,
    onAccept: () => {
      setState((prevState) => ({
        ...prevState,
        isOpen: false
      }))
    },
    onDeny: undefined
  }));
}


export const leftPad = (num:number) => {
  if (num < 10)  {
      return `0${num}`;
  }
  else { 
      return num;
  }
}

export const validateEmail = ((email: string) => {
  const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

  return reg.test(email);
})

export const validatePassword = ((password: string) => {
  const upperOrLowerReg = new RegExp(/[a-zA-Z]/);
  const numberReg = new RegExp(/[0-9]/);
  const specialCharacterReg = new RegExp(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/);

  return upperOrLowerReg.test(password) && numberReg.test(password) && specialCharacterReg.test(password) && password.length >= 10;
})

export function customYMD(epoch:number) {
  const DATE = new Date(epoch);

  const year = DATE.getFullYear();
  const month = leftPad(DATE.getMonth() + 1);
  const date = leftPad(DATE.getDate());

  return `${[year, month, date].join('-')}`;
}

export function epochToYMDHHMM(epoch:number) {
  const DATE = new Date(epoch);

  const year = DATE.getFullYear();
  const month = leftPad(DATE.getMonth() + 1);
  const date = leftPad(DATE.getDate());
  const hour = leftPad(DATE.getHours());
  const min = leftPad(DATE.getMinutes());

  return `${[year, month, date].join('-')} ${hour}:${min}`;
}


export function dateToYMD(d:Date) {

  const DATE = new Date(d);

  const year = DATE.getFullYear();
  const month = leftPad(DATE.getMonth() + 1);
  const date = leftPad(DATE.getDate());

  return `${[year, month, date].join('-')}`;
}

export function dateToYMDHHMM(d:Date) {

  const DATE = new Date(d);

  const year = DATE.getFullYear();
  const month = leftPad(DATE.getMonth() + 1);
  const date = leftPad(DATE.getDate());
  const hour = leftPad(DATE.getHours());
  const min = leftPad(DATE.getMinutes());

  return `${[year, month, date].join('-')} ${hour}:${min}`;
}

export function isEmptyObject(obj:Object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const onClickSelectAll = ((isChecked: boolean) => { // 전체 선택 클릭 시
  const checkboxes = document.getElementsByName('cb');
  checkboxes.forEach((cb) => {
    (cb as HTMLInputElement).checked = isChecked
  })
});

export const updateSelectAll = (() => { // 개별 체크박스 선택 시
  const checkboxes = document.getElementsByName('cb');
  const selectAllCB = document.getElementById('cbAll');

  let isAllChecked:boolean = true;

  checkboxes.forEach((cb) => {
    if(!(cb as HTMLInputElement).checked && selectAllCB != null) {
      (selectAllCB as HTMLInputElement).checked = false;
      isAllChecked = false;
    }
  });

  (selectAllCB as HTMLInputElement).checked = isAllChecked;

});

export const searchAndUpdateData = (keyword: string, data: Array<any>, setState:SetterOrUpdater<any>) => {

  let resultArr:Array<any> = [];
  for (let i=0; i<data.length; i++) {
    const values = Object.values(data[i]);

    for (let j=0; j<values.length; j++) {
      if (String(values[j]).includes(keyword)) {
        resultArr.push(data[i])
        break;
      }
    }
  }
  setState(resultArr);
}

let searchTimer:NodeJS.Timeout;

export const searchAndUpdateDataWithTargetKey = (keyword: string, targetKey: string, data: Array<any>, setState:SetterOrUpdater<any>, setPageState:SetterOrUpdater<any>) => {

  clearTimeout(searchTimer);
  searchTimer = setTimeout(() => {
    let resultArr:Array<any> = [];

    if (keyword === '') {
      setState(data);
      return;
    }

    for (let i=0; i<data.length; i++) {
      if (Object.keys(data[i]).includes(targetKey) && data[i][targetKey].includes(keyword)) {
        resultArr.push(data[i])
      }
    }

    setPageState({
      idx: 0,
      total: Math.ceil(resultArr.length / 10)
    })
    
    setState(resultArr);
  }, 500)
}

export const downloadExcelFile = (data:StatResultTable) => {
  const workbook = XLSX.utils.book_new();

  // 첫 번째 시트 - 사람에 따라
  const dataSheet_person = [
      ["", "일치 비율", "일치된 건 수","총 이벤트"],
      ["통계 요약", data.person.summary.percentMatched, data.person.summary.finalCount,data.person.summary.baseCount],
      ["","","","",""],
      ["", "포함 규칙", "N" , "%만족" , "%증가(To-Gain)"],
      ["1", data.person.inclusionRuleStats[0].name, data.person.inclusionRuleStats[0].countSatisfying,data.person.inclusionRuleStats[0].percentExcluded,data.person.inclusionRuleStats[0].percentSatisfying],
      ["2", data.person.inclusionRuleStats[1].name, data.person.inclusionRuleStats[1].countSatisfying,data.person.inclusionRuleStats[1].percentExcluded,data.person.inclusionRuleStats[1].percentSatisfying],
  ];

  const worksheet1 = XLSX.utils.aoa_to_sheet(dataSheet_person);
  const columnWidths = [
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
  ];
  worksheet1['!cols'] = columnWidths;
  workbook.SheetNames.push('사람에 따라');
  workbook.Sheets['사람에 따라'] = worksheet1;


  // 두 번째 시트 - 이벤트에 따라
  const dataSheet_event = [
      ["", "일치 비율", "일치된 건 수","총 이벤트"],
      ["통계 요약", data.event.summary.percentMatched, data.event.summary.finalCount,data.event.summary.baseCount],
      ["","","","",""],
      ["", "포함 규칙", "N" , "%만족" , "%증가(To-Gain)"],
      ["1", data.event.inclusionRuleStats[0].name, data.event.inclusionRuleStats[0].countSatisfying,data.event.inclusionRuleStats[0].percentExcluded,data.event.inclusionRuleStats[0].percentSatisfying],
      ["2", data.event.inclusionRuleStats[1].name, data.event.inclusionRuleStats[1].countSatisfying,data.event.inclusionRuleStats[1].percentExcluded,data.event.inclusionRuleStats[1].percentSatisfying],
  ];
  const worksheet2 = XLSX.utils.aoa_to_sheet(dataSheet_event);
  worksheet2['!cols'] = columnWidths;
  workbook.SheetNames.push('이벤트에 따라');
  workbook.Sheets['이벤트에 따라'] = worksheet2;


  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const excelData = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  const excelFilename = "Data.xlsx";
  const link = document.createElement("a");
  link.href = URL.createObjectURL(excelData);
  link.download = excelFilename;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

import axios from "axios"
import { SHA512 } from "crypto-js";
import { Setting, SignupInfo, UpdatableUserInfo } from "./interfaces";

const API_HOST = process.env.REACT_APP_API_HOST;

const asyncLocalStorage = {
  setItem: function (key: string, value: string) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key: string) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  }
};




export const login = (email: string, password: string) => {
  return axios.post(`${API_HOST}/auth/login`, {
    'email': email,
    'password': SHA512(password).toString()
  })
  .then((res) => {
    localStorage.setItem('accessToken', res.data.access_token);
    localStorage.setItem('refreshToken', res.data.refresh_token);
    return Promise.resolve(res);
  })
  .catch((err) => {
    console.log(err)
    return Promise.reject(err);
  })
}

export const logout = () => {
  return axios.post(`${API_HOST}/auth/logout`)
  .then((res) => {
    console.log(res)
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`;
    return Promise.resolve(res);
  })
  .catch((err) => {
    console.log(err)
    return Promise.reject(err);
  })
}

export const refreshToken = () => {
  return axios.post(`${API_HOST}/auth/refresh-token`, {
    access_token: localStorage.getItem('accessToken'),
    refresh_token: localStorage.getItem('refreshToken')
  })
  .then(async function (res) {
    if (res.status === 202) return Promise.resolve(res);
    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`;
    await asyncLocalStorage.setItem('accessToken', res.data.access_token);
    await asyncLocalStorage.setItem('refreshToken', res.data.refresh_token);
    return Promise.resolve(res);
  })
  .catch((err) => {
    return Promise.reject(err);
  });
}

export const signup = (signupInfo: SignupInfo) => {
  return axios.post(`${API_HOST}/auth/signup`, {
    'email': signupInfo.email,
    'password': SHA512(signupInfo.password).toString(),
    'email_token': signupInfo.email_token,
    'name': signupInfo.name,
    'phone_number': [signupInfo.phone_first_number, signupInfo.phone_middle_number, signupInfo.phone_last_number].join('')
  })
}

export const requestEmailVerification = (email: string) => {
  return axios.post(`${API_HOST}/auth/email-verification-request`, {
    'email': email
  })
}

export const checkEmailVerification = (email: string, code: string) => {
  return axios.post(`${API_HOST}/auth/email-verification-check`, {
    'email': email,
    'code': code
  })
}

export const requestPasswordResetMail = (name: string, email: string) => {
  return axios.post(`${API_HOST}/auth/password-mail-request`, {
    'name': name,
    'email': email
  })
}

export const getAllHospital = () => {
  return axios.get(`${API_HOST}/hospital`)
}

export const getAllCohorts = () => {
  return axios.get(`${API_HOST}/stats/cohort`)
}

export const postStatsRequest = (recipient_org_ids: Array<number>, cohort_id: number, request_comment: string) => {
  return axios.post(`${API_HOST}/stats/request`, {
    'recipient_org_ids': recipient_org_ids,
    'cohort_id': cohort_id,
    'request_comment': request_comment
  })
}

export const getRequestedStats = () => {
  return axios.get(`${API_HOST}/stats/request`)
}

export const getReceivedStats = () => {
  return axios.get(`${API_HOST}/stats/receive`)
}

export const updateReceivedStat = (stat_id: number, agreed: boolean, reply: string) => {
  return axios.post(`${API_HOST}/stats/receive/${stat_id}`, {
    'agreed': agreed,
    'reply': reply
  })
}

export const getMyInfo = () => {
  return axios.get(`${API_HOST}/info/my`)
}

export const updateMyInfo = (is_noti_enabled: boolean, user_phone: string) => {
  return axios.post(`${API_HOST}/info/my`, {
    'is_noti_enabled': is_noti_enabled,
    'user_phone': user_phone
  })
}

export const updatePassword = (password: string) => {
  return axios.post(`${API_HOST}/info/my/password-reset`, {
    'new_password': SHA512(password).toString()
  })
}

export const resetPassword = (password: string, token: string) => {
  return axios.create().post(`${API_HOST}/auth/password-reset`, {
    'new_password': SHA512(password).toString()
  }, { headers: {
    'Authorization': `Bearer ${token}`
  } })
}


export const getUserRole = () => {
  return axios.get(`${API_HOST}/admin/user-role`)
}
export const getSignupRequests = () => {
  return axios.get(`${API_HOST}/admin/signup-request`)
}
export const updateSignupRequest = (user_id: number, agreed: boolean, user_role: number) => {
  return axios.post(`${API_HOST}/admin/signup-request/${user_id}`, {
    'agreed': agreed,
    'user_role': user_role
  })
}

export const getDBMonitoringInfo = () => {
  return axios.get(`${API_HOST}/admin/monitoring/db`)
}

export const getAdminAllStats = () => {
  return axios.get(`${API_HOST}/admin/stats`)
}

export const getAdminAllUsers = () => {
  return axios.get(`${API_HOST}/admin/users`)
}

export const withdrawUser = (user_id: number) => {
  return axios.post(`${API_HOST}/admin/users/${user_id}/withdraw`)
}

export const getAllNotice = () => {
  return axios.get(`${API_HOST}/notice`)
}
export const postNotice = (title: string, content: string) => {
  return axios.post(`${API_HOST}/notice`, {
    'title': title,
    'content': content
  })
}


export const getNotice = (id: number) => {
  return axios.get(`${API_HOST}/notice/${id}`)
}

export const deleteNotice = (id: number) => {
  return axios.delete(`${API_HOST}/notice/${id}`)
}

export const updateNotice = (id: number, title: string, content: string) => {
  return axios.put(`${API_HOST}/notice/${id}`, {
    'title': title,
    'content': content
  })
}


export const getAdminSetting = () => {
  return axios.get(`${API_HOST}/admin/setting`)
}

export const updateAdminSetting = (setting: Setting) => {
  return axios.post(`${API_HOST}/admin/setting`, setting)
}

export const requestAdminPasswordResetMail = (id: number) => {
  return axios.post(`${API_HOST}/admin/users/${id}/password-reset`)
}
export const updateAdminUserInfo = (id: number, user_info: UpdatableUserInfo) => {
  return axios.post(`${API_HOST}/admin/users/${id}`, user_info)
}

export const getStatResultTable = (id: number) => {
  return axios.get(`${API_HOST}/stats/${id}/table`)
}